.container-expanded {
   padding-left: 165px;
}
.Content-Body {
   margin-top: 50px;
}
.Content-Box {
   background-clip: border-box;
   border: 0 solid rgba(0, 0, 0, 0.125);
   border-radius: 0.25rem;
   padding: 20px;
   margin-bottom: 1rem;
   box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}
.content-small {
   background-clip: border-box;
   border: 0 solid rgba(0, 0, 0, 0.125);
   border-radius: 0.25rem 0.25rem 0 0;
   padding: 10px;
   box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}
.content-card-box {
   background-color: #fff;
}

.color-info {
   background-color: #17a2b8;
   color: #fff;
}

.color-success {
   background-color: #28a745;
   color: #fff;
}

.color-warning {
   background-color: #ffc107;
   color: #000;
}

.color-danger {
   background-color: #dc3545;
   color: #fff;
}

.content-small-link {
   border-radius: 0 0 0.25rem 0.25rem;
   padding: 5px;
}

.link-color-info {
   background-color: #1591a5;
   color: #fff;
}

.link-color-success {
   background-color: #24963e;
   color: #fff;
}

.link-color-warning {
   background-color: #e5ad06;
   color: #000;
}

.link-color-danger {
   background-color: #c6303e;
   color: #fff;
}

.icon-color {
   color: rgba(0, 0, 0, 0.15);
}
