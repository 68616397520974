body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
      "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.loading-circle-container {
   overflow: hidden;
   position: relative;
   width: 100%;
}

.loading-screen-blur {
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: 99;
   backdrop-filter: saturate(180%) blur(5px);
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.loading-screen-blur-spinner {
   color: var(--purple);
}

.feed-badge {
   background-color: #692f90 !important;
   color: #fff;
   padding: 6px 10px;
   margin-bottom: 10px;
}

.profile_tab_style {
   border-bottom: none;
   color: #707070;
   font-size: 18px;
   font-weight: 400;
}

.profile_tab_style a {
   color: #707070;
}

.profile_tab_style .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
   color: #495057;
   background-color: #f4f5fa;
   border-color: #f4f5fa;
   border-bottom: 3px solid #707070;
   font-weight: 500;
}

.profile_tab_body {
   padding-left: 1.2rem;
   padding-right: 1.2rem;
   background-color: #fff;
   margin-top: 30px;
   border-radius: 10px;
   margin-bottom: 30px;
}

.data_diri_tab_menu {
   border-right: 1px solid #f4f5fa;
   padding-left: 30px;
   padding-right: 30px;
}

.data_diri_tab_menu_title {
   color: #160e25;
   font-size: 16px;
   font-weight: 500;
}

.data_diri_tab_title a {
   color: #707070;
   font-weight: 400;
   font-size: 16px;
   padding: 15px;
}

.data_diri_tab_title .nav-link.active {
   color: #fff;
   background-color: #f4f5fa;
}

.data_diri_tab_body {
   padding-left: 30px;
   padding-right: 30px;
}

.data_diri_tab_body_title {
   color: #160e25;
   font-size: 16px;
   font-weight: 500;
}

.tab_body_underline {
   border-top: 1px solid #f4f5fa;
   margin-top: 0;
   margin-bottom: 0;
}

.field_plaint_text {
   color: #707070;
   font-size: 16px;
}

.field_plaint_text.form-control {
   background-color: #fff !important;
   border: 1px solid #e0e0e0 !important;
   padding: 22px 10px;
}

/* portfolio */

.portfolio_card {
   border-radius: 10px;
   border: 1.5px solid #efebe3;
   margin-bottom: 25px;
   transition: 0.5s;
   width: 16rem;
   margin-left: 5px;
   margin-right: 5px;
   -webkit-transition: 0.5s;
   -moz-transition: 0.5s;
   transition: 0.5s;
}

.portfolio_card:hover {
   box-shadow: 15px 15px 27px #e1e1e3, -3px -3px 27px #ffffff;
   cursor: pointer;
}

.portfolio_card_image {
   border-radius: 10px 10px 0 0;
   width: 100%;
   height: 180px;
   object-fit: cover;
}

/* Custom Card Style */

.product_card {
   border-radius: 10px;
   border: 1.5px solid #efebe3;
   margin-bottom: 25px;
   transition: 0.5s;
   width: 16rem;
   margin-left: 5px;
   margin-right: 5px;
   -webkit-transition: 0.5s;
   -moz-transition: 0.5s;
   transition: 0.5s;
}

.product_card:hover {
   box-shadow: 15px 15px 27px #e1e1e3, -3px -3px 27px #ffffff;
   cursor: pointer;
}

.product_card_image {
   border-radius: 10px 10px 0 0;
   width: 100%;
   height: 200px;
   object-fit: cover;
}

.product_category_text {
   color: #75628d;
   font-size: 12px;
   font-weight: 700;
   margin: 0 0 16px 0;
}

.product_title_text {
   color: #707070;
   font-size: 16px !important;
   font-weight: 500;
}

.product_starts_text {
   font-size: 12px;
   font-weight: 400;
   color: #707070;
}

.product_price_text {
   color: #707070;
   font-size: 16px !important;
   font-weight: 500;
}

/* product section */
.product_box {
   margin-bottom: 50px;
   background-color: #ffffff;
}

.product_picture {
   padding: 30px;
}

.product-card-column {
   column-count: 4;
}

.product_content {
   padding: 30px 30px 30px 0;
}
.product_detail_category_text {
   font-size: 14px;
   font-weight: 600;
   color: var(--purple);
}
.product_detail_title_text {
   font-size: 20px;
   font-weight: 500;
   color: #303030;
}

.product_detail_pricelabel_text {
   font-size: 16px;
   font-weight: 400;
   color: #707070;
}

.product_detail_price_text {
   font-size: 16px;
   font-weight: 400;
   color: #303030;
}

.product_detail_badge {
   border: 1px solid #efebe3;
   font-size: 16px;
   font-weight: 400;
   color: #303030;
   padding: 5px 15px;
}

.product_upload_file::-webkit-file-upload-button {
   visibility: hidden;
}

.image_container {
   padding-bottom: 100%;
   overflow: hidden;
   position: relative;
}
/* Customer Product */

.customer_product_title {
   color: #3d3d3d;
   font-size: 18px;
   font-weight: 400;
}

.customer_product_rate {
   color: #3d3d3d;
   font-size: 14px;
}

.customer_product_rate_subtitle {
   color: #8b8b8b;
   font-size: 12px;
}

.customer_product_price_range {
   color: #3d3d3d;
   font-size: 24px;
   background: #fbfbfb;
   font-weight: 500;
}

.customer_product_features_title {
   color: #8b8b8b;
   font-size: 14px;
}

.customer_product_features_content {
   color: #000000;
   font-size: 14px;
}

.customer_product_more_efficient {
   color: #8b8b8b;
}

.customer_product_detail_badge {
   border: 1px solid #efebe3;
   font-size: 14px;
   font-weight: 400;
   color: #8b8b8b;
   padding: 5px 15px;
}

.customer_product_variant_badge {
   border: 1px solid #efebe3;
   font-size: 14px;
   font-weight: 400;
   color: #8b8b8b;
   padding: 5px 15px;
   cursor: pointer;
}

.customer_product_variant_badge_selected {
   background: rgba(105, 47, 144, 0.05);
   border: 1px solid #692f90;
   font-size: 14px;
   font-weight: 400;
   color: #303030;
   padding: 5px 15px;
   cursor: pointer;
}

.customer_item_sharable {
   color: #8b8b8b;
   font-size: 14px;
   transition: 0.5s;
}

.customer_item_sharable:hover {
   cursor: pointer;
   color: var(--purple);
}

.customer_item_favorited {
   cursor: pointer;
   color: var(--purple);
   transition: 0.5s;
   font-size: 14px;
}

.customer_item_favorited:hover {
   color: #8b8b8b;
}

.product_profile_title {
   color: #3d3d3d;
   font-size: 18px;
   font-weight: 400;
}
.product_last_active {
   color: #8b8b8b;
   font-size: 14px;
   font-weight: 400;
}
.customer_product_profile_title {
   color: #8b8b8b;
   font-size: 14px;
   font-weight: 400;
}
.customer_product_profile_content {
   color: #692f90;
   font-size: 14px;
   font-weight: 400;
   margin-left: 8px;
}
.customer_product_desc_title {
   font-size: 18px;
   color: #3d3d3d;
   font-weight: 400;
   background-color: #fbfbfb;
   padding: 20px;
}
.customer_product_desc {
   font-size: 14px;
   color: #8b8b8b;
   font-weight: 400;
}
.customer_product_review_title {
   color: #3d3d3d;
   font-size: 18px;
   font-weight: 400;
}
.customer_product_review_point {
   color: #3d3d3d;
   font-size: 48px;
   font-weight: 400;
}
.customer_product_review_pointof {
   color: #8b8b8b;
   font-size: 18px;
   font-weight: 400;
   position: absolute;
   top: 15px;
   margin-left: 15px;
}
.customer_product_review_badge {
   cursor: pointer;
   border: 1px solid #efebe3;
   font-size: 14px;
   font-weight: 400;
   color: #8b8b8b;
   padding: 8px 30px;
}
.customer_product_review_badge.active {
   border-color: #944c99 !important;
   color: #944c99 !important;
}
.customer_product_review_starbox {
   padding: 30px;
   background-color: #fbfbfb;
}
.customer_product_review_commentbox {
   padding: 30px;
}
.customer_product_review_name {
   color: #3d3d3d;
   font-size: 14px;
   font-weight: 400;
}
.customer_product_review_comment {
   color: #8b8b8b;
   font-size: 14px;
   font-weight: 400;
}
.customer_product_review_date {
   color: #8b8b8b;
   font-size: 14px;
   font-weight: 400;
}
.customer_product_rate_boundary {
   color: #ebebeb;
}

.customer_banner_image {
   height: 400px;
   object-fit: cover;
}

.customer_product_thumb {
   width: 100%;
   height: 80px;
   object-fit: cover;
}

.product_image_slide {
   height: 500px;
   object-fit: cover;
}

/*button*/
.button-pill-primary {
   background: #692f90 !important;
   font-size: 14px;
   padding: 7px 40px;
   font-weight: 500;
   border-color: #692f90 !important;
   border-radius: 2rem !important;
   color: #fff !important;
   transition: 0.5s;
}

.button-pill-primary:hover {
   background: #692f90 !important;
   border-color: #692f90 !important;
   border-radius: 2rem !important;
   color: #fff !important;
}

.button-pill-primary:focus {
   background: #692f90;
   box-shadow: 0 0 0 0.2rem rgb(112 112 112 / 0.5);
   border-color: #692f90;
}

.button-pill-primary:not(:disabled):not(.disabled):active {
   background: #692f90;
   box-shadow: 0 0 0 0.2rem rgb(112 112 112 / 0.5);
   border-color: #692f90;
}

.button-pill-primary.disabled,
.button-pill-primary:disabled {
   color: #fff !important;
   background-color: #ddd2eb;
   border-color: #ddd2eb;
   cursor: not-allowed;
}

.button-pill-outline-primary {
   border-radius: 2rem !important;
   font-size: 14px;
   padding: 7px 40px;
   font-weight: 500;
   background-color: #ffffff00 !important;
   border-color: #692f90 !important;
   color: #692f90 !important;
   transition: 0.5s;
}

.button-pill-outline-primary:hover {
   background: var(--orange) !important;
   border-color: var(--orange) !important;
   color: #fff !important;
}

.button-pill-outline-primary:focus {
   background: #692f90;
   box-shadow: unset !important;
   border-radius: 2rem !important;
   border-color: #692f90;
}

.button-pill-outline-primary:not(:disabled):not(.disabled):active {
   background: #692f90;
   box-shadow: unset !important;
   border-color: #692f90;
}

.button-pill-outline-primary.disabled,
.button-pill-outline-primary:disabled {
   color: #fff;
   background-color: #ddd2eb;
   border-color: #ddd2eb;
   cursor: not-allowed;
}

.button-pill-outline-secondary {
   border-radius: 2rem;
   font-size: 14px;
   padding: 7px 40px;
   font-weight: 500;
   background-color: #ffffff00 !important;
   border: 1px solid #f0f0f0 !important;
   color: #a0a0a0 !important;
   transition: 0.5s;
}

.button-pill-outline-secondary:hover {
   background-color: #ffffff00 !important;
   border-radius: 2rem !important;
   border-color: #692f90 !important;
   color: #692f90 !important;
}

.button-pill-outline-secondary:focus {
   background: #692f90;
   box-shadow: unset !important;
   border-color: #692f90;
   color: #692f90 !important;
}

.button-pill-outline-secondary:not(:disabled):not(.disabled):active {
   background: #692f90;
   box-shadow: unset !important;
   border-color: #692f90;
}

.button-pill-outline-secondary.disabled,
.button-pill-outline-secondary:disabled {
   color: #fff;
   background-color: #ddd2eb;
   border-color: #ddd2eb;
   cursor: not-allowed;
}

.btn {
   font-size: 12px !important;
   padding: 5px 10px !important;
}

.button-outline-danger {
   border-radius: 8px;
   font-size: 14px;
   padding: 7px 40px;
   font-weight: 500;
   background-color: #ffffff00 !important;
   border-color: #dc3545 !important;
   color: #dc3545 !important;
   transition: 0.5s;
}

.button-outline-danger:hover {
   background: #dc3545 !important;
   border-color: #dc3545 !important;
   color: #fff !important;
}

.button-outline-danger:focus {
   background: #dc3545 !important;
   box-shadow: unset !important;
   border-color: #dc3545 !important;
   color: #fff !important;
}

.button-outline-danger:not(:disabled):not(.disabled):active {
   background: #dc3545 !important;
   box-shadow: unset !important;
   border-color: #dc3545 !important;
}

.button-outline-danger.disabled,
.button-outline-danger:disabled {
   color: #fff;
   background-color: #ddd2eb;
   border-color: #ddd2eb;
   cursor: not-allowed;
}

/* bar loader */
.laoding-bar-container {
   align-items: center;
   display: flex;
   justify-content: center;
}
.lds-facebook {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
}
.lds-facebook div {
   display: inline-block;
   position: absolute;
   left: 8px;
   width: 16px;
   background: rgba(105, 47, 144, 0.2);
   animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
   left: 8px;
   animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
   left: 32px;
   animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
   left: 56px;
   animation-delay: 0;
}

.badge-tag {
   background: #692f9020 0% 0% no-repeat padding-box;
   font-size: 14px;
   color: #692f90;
   padding: 5px 20px !important;
   font-weight: normal !important;
}

.secondary-badge-tag {
   background: #fdf6ee;
   font-size: 14px;
   color: #692f90;
   padding: 5px 20px !important;
   font-weight: normal !important;
}

@keyframes lds-facebook {
   0% {
      top: 8px;
      height: 64px;
   }
   50%,
   100% {
      top: 24px;
      height: 32px;
   }
}

.card-counter {
   border-radius: 10px;
   display: flex;
}

.card-counter-value {
   line-height: 35px;
}

.card-counter-label {
   font-size: 14px;
   font-weight: 600;
}

.card-warning-color {
   background-color: rgb(245, 193, 66);
}

.card-info-color {
   background-color: rgb(73, 162, 183);
}

.card-success-color {
   background-color: rgb(76, 168, 68);
}

.card-danger-color {
   background-color: rgb(220, 53, 70);
}

.expertise-tag {
   background: #f5d9ff 0% 0% no-repeat padding-box;
   font-size: 11px !important;
   color: #692f90 !important;
   padding: 3px 8px;
   font-weight: normal;
   letter-spacing: 0.1px;
   line-height: 14px;
}

.badge-outline-primary {
   color: var(--purple) !important;
   border: 1px solid #692f90;
   background-color: #ffffff00 !important;
   border-radius: 2rem;
   font-weight: 400;
   padding: 5px 10px;
}