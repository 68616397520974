.result_album_content {
    flex-flow: wrap;
}

.result_media_item {
    width: 120px;
    height: 120px;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover !important;
    background-position: center !important;
}

.check_result_file {
    width: 20px;
    margin: 10px 0 0 10px;
    height: 20px;
}

.filename_label {
    font-size: 12px;
    font-weight: 500;
    color: #656565;
    width: 120px;
}

.media-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background: linear-gradient(180deg, #944c99 0%, #6a308b 100%);
}

.media-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    position: absolute;
    left: 10px;
    top: 10px;
}

.media-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
    position: absolute;
    left: 10px;
    top: 10px;
}

.default-event {
    width: 100%;
    height: 100px;
    background-color: #6a308b;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.default-event-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(29 33 36 / 35%);
    border-radius: 20px;
}

.default-event-title {
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    z-index: 9;
}