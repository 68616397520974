.chat_container {
   background-color: #fff;
}

.chat_room_container {
   height: 500px;
   max-height: 500px;
}

.chat_label {
   font-style: normal;
   font-weight: 600;
   font-size: 24px;
   line-height: 36px;
   color: #303030;
}

.chat_room {
   border-right: 1px solid #f4f5fa;
   padding-left: 20px;
   padding-right: 20px;
}

.chat_room_content {
   border-right: 1px solid #f4f5fa;
   padding-left: 20px;
   padding-right: 20px;
}

.chat_room_list {
   cursor: pointer;
}

.chat_room_list .profile_picture {
   height: 36px;
   width: 36px;
   border-radius: 40% 0;
   object-fit: cover;
}

.chat_room_list .room_name {
   display: flex;
   flex-basis: auto;
   flex-direction: column;
   flex-grow: 1;
   justify-content: center;
   min-width: 0;
}
.chat_room_list .room_name .project_name_group {
   display: flex;
   align-items: center;
   line-height: normal;
}

.chat_room_list .room_name .project_name_group .project_name {
   display: flex;
   flex-grow: 1;
   overflow: hidden;
}

.chat_room_list .room_name .project_name_group .project_name span {
   position: relative;
   flex-grow: 1;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 14px;
   color: #303030;
   margin-right: 15px;
}

.chat_room_list .room_name .project_name_group .last_chat_time {
   font-style: normal;
   font-weight: normal;
   font-size: 10px;
   line-height: 14px;
   color: #a0a0a0;
}

.chat_room_list .last_chat {
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 14px;
   color: #a0a0a0;
   margin-top: 5px;

   flex-grow: 1;
   overflow: hidden;
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.chat_room_list .chat_unread {
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   line-height: 14px;
   background-color: rgba(105, 47, 144, 0.2) !important;
   color: #692f90;
   margin-top: 5px;
   width: 35px;
}

.chat_message .header {
   position: relative;
   z-index: 1000;
   box-sizing: border-box;
   display: flex;
   align-items: center;
   width: 100%;
   height: 59px;
   margin-top: 10px;
}

.chat_message .header .profile_picture {
   height: 36px;
   width: 36px;
   border-radius: 40% 0;
   object-fit: cover;
}

.chat_message .header .room_name {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   justify-content: center;
   min-width: 0;
}

.chat_message .header .room_name .project_name_group .project_name {
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 14px;
   color: #303030;
}

.chat_message .header .room_name .user_name {
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 12px;
   color: #a0a0a0;
   margin-top: 8px;
}

.chat_message .content {
   position: relative;
   z-index: 1;
   flex: 1 1 0;
   order: 2;
   min-height: 350px;
}

.chat_message .content .content_container {
   position: absolute;
   top: 0;
   z-index: 100;
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
   overflow-x: hidden;
   overflow-y: scroll;
   flex-direction: column-reverse;
   transition: background 0.3s ease-out 0.1s;
}

.chat_message .content .content_container .blank_spot {
   flex: 1 1 auto;
   min-height: 12px;
}

.chat_message .content .content_container .blank_spot {
   position: absolute;
   bottom: 17px;
   z-index: 200;
   display: inline-flex;
   flex-direction: column;
}

.chat_message .content .content_container .message_content {
   flex: 0 0 auto;
}
.chat_message .content .content_container .message_content .message_info_list {
   display: flex;
   flex-direction: row;
   padding-right: 12%;
   padding-left: 12%;
}

.chat_message .content .content_container .message_content .message_list {
   position: relative;
   display: flex;
   flex-direction: column !important;
   -moz-box-orient: horizontal;
   -moz-box-direction: normal;
   flex-direction: row;
   padding-right: 4%;
   padding-left: 4%;
   margin-bottom: 15px;
}

.chat_message .content .content_container .message_content .message_in {
   align-items: flex-start;
}

.chat_message .content .content_container .message_content .message_out {
   align-items: flex-end;
}

.chat_message .content .content_container .message_content .message_list .message_list_item {
   padding: 5px 12px 6px;
}

.chat_message
.content
.content_container
.message_content
.message_info_list
.message_list_item
.message_info {
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 18px;
   text-align: center;
   color: #a0a0a0;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item.message_in {
   max-width: 65%;
   background: #ffffff;
   border: 1px solid #f0f0f0;
   box-sizing: border-box;
   box-shadow: 0px 5px 10px rgba(48, 48, 48, 0.05);
   border-radius: 0px 10px 10px 10px;
}
.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item.message_out {
   max-width: 65%;
   background: #f0f0f0;
   box-shadow: 0px 5px 10px rgba(48, 48, 48, 0.05);
   border-radius: 10px 0px 10px 10px;
}
.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box {
   position: relative;
   flex: none;
   font-size: 14.2px;
   line-height: 19px;
   color: #000;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_text {
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 19px;
   color: #303030;
   display: block;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_image {
   width: 250px;
   cursor: pointer;
   position: relative;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_multiple_image {
   width: 250px;
   cursor: pointer;
   position: relative;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_multiple_image img {
   width: 100%;
   height: 100%;
   border-radius: 10px;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_multiple_image
.image_overlay {
   position: absolute;
   width: 100%;
   height: 100%;
   background-color: #00000080;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #fff;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_image
img {
   width: 100%;
   height: 100%;
   border-radius: 10px;
   margin-top: 10px;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_album {
   position: absolute;
   bottom: 0;
   padding: 10px;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_image
.border {
   position: absolute;
   width: 100%;
   height: 100%;
   background: linear-gradient(180deg, rgba(224, 224, 224, 0) 50%, rgba(48, 48, 48, 0.8) 100%);
   border-radius: 10px;
}

.media_album_container {
   position: relative;
   display: flex;
   width: 100%;
   height: 100%;
}

.media_album_border {
   position: absolute;
   width: 100%;
   height: 100%;
   background: linear-gradient(180deg, rgba(224, 224, 224, 0) 50%, rgba(48, 48, 48, 0.8) 100%);
   border-radius: 10px;
}

.media_album {
   position: absolute;
   padding: 10px;
   width: 100%;
   bottom: 0;
   padding: 10px;
}

.media_album .album_name {
   font-weight: 600;
   font-size: 12px;
   line-height: 8px;
   color: #ffffff;
}

.media_album .last_added {
   font-size: 10px;
   line-height: 10px;
   color: #ffffff;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_album
.album_name {
   font-style: normal;
   font-weight: 600;
   font-size: 12px;
   line-height: 12px;
   color: #ffffff;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_album
.last_added {
   font-style: normal;
   font-weight: normal;
   font-size: 10px;
   line-height: 10px;
   color: #ffffff;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_video {
   padding: 10px;
   display: block;
   cursor: pointer;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_video
img {
   border-radius: 10px;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_video
.video_title {
   text-overflow: ellipsis;
   overflow-x: hidden;
   overflow-y: hidden;
   margin-right: 10px;
   margin-left: 10px;
   align-self: center;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.message_time {
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 16px;
   color: #a0a0a0;
   margin: 5px 0;
   display: block;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.mitra_profile {
   font-size: 12px;
   letter-spacing: 0px;
   color: #a0a0a0;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.mitra_profile
.image_frame {
   height: 24px;
   width: 24px;
   border-radius: 40% 0;
}

.chat_message
.content
.content_container
.message_content
.message_list
.message_list_item
.message_box
.mitra_profile
.image_frame
.profile_image {
   border-radius: 40% 0;
   display: flex;
   margin-left: auto;
   margin-right: auto;
   margin-top: 9px;
   border: 2px solid #fff;
   width: 22px;
   height: 22px;
}

.chat_message .footer {
   position: relative;
   z-index: 1;
   box-sizing: border-box;
   flex: none;
   order: 3;
   width: 100%;
   min-height: 62px;
   margin-top: 10px;
}

.chat_message .footer .footer_section {
   position: relative;
   flex: 1;
   width: 100%;
   min-width: 0;
   min-height: 52px;
}

.chat_message .footer .footer_section .footer_separator {
   display: flex;
   align-items: flex-end;
}

.chat_message .footer .footer_section .footer_separator .message_sender {
   display: flex;
   flex: 1;
   align-items: flex-end;
   min-width: 0;
}

.chat_message .footer .footer_section .typing_box {
   box-sizing: border-box;
   flex: 1 1 auto;
   width: inherit;
   min-width: 0;
   min-height: 20px;
   font-size: 15px;
   font-weight: 400;
   line-height: 20px;
   outline: none;
   will-change: width;
   margin: 0 12px;
}

.chat_message .footer .footer_section .typing_box .typing_input {
   height: 50px;
   resize: none;
}

.chat_form {
   display: flex;
   flex-grow: inherit;
}

.chat_message .footer .footer_section .sending_box {
   box-sizing: border-box;
   display: flex;
   align-items: center;
   justify-content: center;
   min-height: 52px;
}

.chat_message .footer .footer_section .sending_box .sending_button {
   height: 50px;
   padding: 0 15px;
   background: #ffffff;
   border: 1px solid #f0f0f0;
   box-sizing: border-box;
   border-radius: 5px;
}

.chat_extra_link {
   font-weight: 600;
   line-height: 19px;
   text-align: right;
   color: #692f90;
   cursor: pointer;
}

.request_admin_modal_header {
   padding: 15px 30px;
}

.request_admin_modal_content {
   padding: 15px 30px;
}

.request_admin_modal_title {
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   color: #303030;
}

.request_admin_close {
   color: #707070;
   cursor: pointer;
   transition: 0.5s;
}

.info_badge {
   background-color: rgba(105, 47, 144, 0.2) !important;
   color: #692f90;
   padding: 6px 10px;
   margin-bottom: 10px;
}

.add_attachment_button {
   width: 60px;
   background: none;
   border: none;
}

.add_attachment_button_label {
   font-weight: 600;
   font-size: 12px;
   line-height: 21px;
   text-align: center;
   color: #303030;
}

.add_attachment_body {
   display: flex;
   justify-content: center;
}

.file_upload_label {
   color: #692f90;
   /* width: 80px; */
   height: 90px;
   display: grid;
   align-items: center;
}

.upload_album {
   height: 120px;
   min-height: 120px;
   width: 120px;
}

.upload_loading {
   width: 89%;
   height: 88%;
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
   background: #ffffffcc;
}

.detail_attachment_content {
   display: flex;
   justify-items: center;
   justify-content: center;
   padding-top: 10px;
   padding-bottom: 10px;
}

.detail_attachment_content img {
   max-width: 400px;
   height: auto;
}

.album_media {
   width: 120px;
   height: 120px;
   background-repeat: no-repeat;
   border-radius: 10px;
   background-size: cover !important;
   background-position: center !important;
   cursor: pointer;
}

.album_media_modal_content {
   padding: 15px 15px;
   flex-flow: wrap;
}

.album_media_delete_button {
   padding: 2px 5px 4px 5px;
   position: absolute;
   right: 5px;
   top: 5px;
   border: none;
   border-radius: 5px;
   background: none;
   transition: 0.5s;
}

.album_media_delete_button:hover {
   background: #f93154;
   border-radius: 50%;
}

.media_list_title {
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   color: #303030;
}

.album_file {
   padding: 10px;
   text-align: center;
   cursor: pointer;
   border-radius: 10px;
   transition: 0.5s;
   width: 100px;
   height: 100px;
   background-repeat: no-repeat;
   background-size: cover !important;
   background-position: center !important;
}

@media only screen and (max-width: 767px) {
   .chat_room_container {
      height: 650px;
      max-height: 650px;
   }
   .chat_room_content {
      height: 650px;
   }
   .chat_message .content {
      min-height: 500px;
   }
   .chat_message
   .content
   .content_container
   .message_content
   .message_list
   .message_list_item.message_out {
      max-width: none;
   }
   .chat_message
   .content
   .content_container
   .message_content
   .message_list
   .message_list_item.message_in {
      max-width: none;
   }
}

.multiple_picture_list {
   display: flex;
   border-bottom: 1px solid #f0f0f0;
   padding-bottom: 10px;
}


.multiple_picture_list .file_name {
   font-weight: 600;
   font-size: 14px;
   color: #303030;
}

.multiple_picture_list .file_size {
   font-size: 12px;
   color: #A0A0A0;
}

.multiple_picture_list .trash {
   color: #ff4c55;
   cursor: pointer;
}