.empty_portfolio {
  margin-bottom: 4rem;
  padding-bottom: 5rem;
}

.empty_portfolio_image {
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.empty_portfolio_title {
  font-weight: 500;
  color: #707070;
  font-size: 16px;
}

.portfolio_badge {
  background-color: #efebe3;
  color: #707070;
  font-weight: 400;
  font-size: 14px;
}

.slider_next_icon {
  background-image: url("../../../../assets/icons/slide_next_icon.svg");
  width: 36px;
  height: 37px;
}

.slider_prev_icon {
  background-image: url("../../../../assets/icons/slide_prev_icon.svg");
  width: 36px;
  height: 37px;
}
