.customer_modal_close {
    color: #707070;
    cursor: pointer;
    transition: 0.5s;
}

.customer_modal_close:hover {
    color: #692f90;
}

.create_project_container {
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.create_project_title {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
}

.detail_activity_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #303030;
}

.create_project_activity_card {
    border: 1px solid #f0f0f0;
    border-top: 5px solid #edd3ff;
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
}

.project_trash_button {
    background: #e0e0e0;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    transition: 0.5s;
}

.project_trash_button:hover {
    color: #f27474;
}

.project_vendors_title {
    font-weight: 600;
    font-size: 24px;
}

.project_vendors_dots_link {
    text-decoration: none;
    color: #c0c0c0;
}

.project_vendors_dots {
    color: #c0c0c0;
}

.project_vendors_title_activity {
    font-weight: 500;
    font-size: 16px;
    color: #303030;
}

.project_activity_small_label {
    font-size: 12px;
    color: #a0a0a0;
}

.project_activity_small_label i {
    font-size: 12px;
    color: #a0a0a0;
}

.project_activity_description {
    font-size: 14px;
    line-height: 28px;
    color: #303030;
}

.project_vendors_description {
    font-size: 14px;
    line-height: 28px;
    color: #303030;
    font-weight: 500;
}

.project_horizontal_card {
    display: flex;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
}

.project_note_containers{
    border: 1px solid #692F90;
    border-radius: 8px;
    padding: 12px;
}

.project_activity_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #3d3d3d;
    flex-grow: 1;
}

.project_activity_subtitle {
    font-size: 12px;
    letter-spacing: 0px;
    color: #a0a0a0;
}

.project_activiry_expertise {
    margin-right: 4px;
    border-radius: 10px;
    background-color: #eee;
    padding: 3px 8px;
    color: #555;
    font-family: Proxima Nova, Helvetica, arial, sans-serif;
    font-size: 11px;
    letter-spacing: 0.1px;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    float: left;
    text-align: center;
}

.project_activity_expertise {
    margin-right: 4px;
    border-radius: 10px;
    background-color: #eee;
    padding: 3px 8px;
    color: #555;
    font-family: Proxima Nova, Helvetica, arial, sans-serif;
    font-size: 11px;
    letter-spacing: 0.1px;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    float: left;
    text-align: center;
}

.project_activity_thumbnail_image {
    max-height: 120px;
    max-width: 200px;
    width: 200px;
    overflow: hidden;
    position: relative;
    border-radius: 10px 0 0 10px;
}

.project_activity_thumbnail_image img {
    width: 200px;
}


.project_activity_image {
    border-radius: 10px 0 0 10px;
}

.project_activity_profile_image_frame {
    height: 24px;
    width: 24px;
    border-radius: 40% 0;
}

.project_activity_profile_image {
    border-radius: 40% 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 9px;
    border: 2px solid #fff;
    width: 22px;
    height: 22px;
    object-fit: cover;
}

.add_vendors_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
}

.add_activity_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #303030;
}

.project_mitra_type_title {
    font-size: 12px;
    text-align: center;
}

.specific_vendor_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #3d3d3d;
}

.add_to_project_modal_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
}

.add_to_project_modal_subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #692f90;
}

.add_to_project_modal_project_name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
}

.add_to_project_modal_project_subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #303030;
}

.project_modal_content_box {
    max-height: 400px;
    overflow: auto;
}

.payment_service_horizontal_card {
    display: flex;
    flex-direction: column;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    padding: 0 10px 10px 10px;
    border-radius: 10px;
    cursor: pointer;
}

.payment_service_thumbnail_image {
    max-height: 120px;
    max-width: 200px;
    width: 200px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
}

.payment_service_image {
    border-radius: 5px;
}

.payment_service_title {
    font-size: 14px;
    line-height: 21px;
    color: #303030;
    font-weight: 600;
}

.payment_service_subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #303030;
}

.payment_info_container {
    padding: 0 2rem;
}

.payment_info_box_top {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0 0;
    padding: 20px;
    border-bottom: 5px solid #f0f0f0;
}

.payment_info_box_bottom {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0 0 5px 5px;
    padding: 20px;
}

.payment_info_discount_button {
    padding: 10px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
    font-weight: 600;
    transition: 0.5s;
}

.payment_info_discount_button:hover {
    background: rgba(105, 47, 144, 0.05);
    border: 1px solid #692f90;
}

.payment_pricelist {
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.discount_modal_header {
    padding: 15px 30px;
}

.dicount_list_separated {
    width: 100%;
    height: 5px;
    background-color: #f0f0f0;
}

.voucher_card_name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
}

.voucher_card_name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
}

.voucher_card_description {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.voucher_card_detail_link {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #692f90;
}

.voucher_card_detail_link:hover {
    color: #692f90;
}

.discount_modal_detail_header {
    width: 100%;
    height: 150px;
    border-radius: 10px 10px 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.discount_modal_back_button {
    cursor: pointer;
}

.voucher_detail_label {
    font-size: 14px;
    line-height: 21px;
    color: #a0a0a0;
}

.voucher_detail_sublabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.voucher_detail_content {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #a0a0a0;
    max-height: 200px;
    overflow: auto;
}

/* cart styles */
.cart_page_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #303030;
}

.cart_project_name {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.cart_activity_name {
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    color: #303030;
}

.payment_cart_thumbnail_image {
    max-height: 100px;
    max-width: 100px;
    width: 120px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
}

.cart_project_checkbox {
    align-self: center;
}

.cart_project_price {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #f37334;
}

.cart_project_line {
    position: absolute;
    width: 100%;
    left: 0;
}

.cart_project_line hr {
    border-top: 1px solid #f0f0f0;
}

.cart_project_list {
    margin-bottom: 20px;
}

.cart_info_box_bottom {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 20px;
}

.payment_summary_container {
    min-height: 300px;
}

.payment_summary_total {
    box-shadow: 0px -5px 10px rgba(48, 48, 48, 0.05);
}

.payment_method_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #303030;
}

.payment_method_show_all {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #692f90;
    cursor: pointer;
}

.payment_method_name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #303030;
}

.payment_method_total_title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.payment_method_total {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #f37334;
}

.payment_method_list {
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
}

.payment_method_list_name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.payment_method_selected_payment {
    cursor: pointer;
}

.summary_title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 20px;
    color: #303030;
}

.summary_info {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.invoice_total {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: #303030;
}

.invoice_id {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--purple);
}

.summary_title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 20px;
    color: #303030;
}

.gridlist_button {
    border: 1px solid #ced4da;
    border-radius: 5px;
    background-color: #ffffff00;
    padding: 5px 10px;
    color: #a0a0a0;
}

.project_new_project {
    display: inline-block;
    position: relative;
}

.project_new_project_bubble {
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    right: 4px;
}

.project_request_card_name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
}

.project_request_name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
}

.project_request_description_container {
    width: 100%;
}

.project_request_description {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.project_request_detail_link {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #692f90;
}

.project_request_detail_link:hover {
    color: #692f90;
}

.payment_time_left {
    color: var(--purple);
    font-size: 50px;
    font-weight: 600;
}

.payment_time_left_info {
    color: var(--purple);
    font-weight: 500;
}

.invoice_modal_box {
    max-height: 500px;
    overflow: auto;
}

.result_album_content {
    flex-flow: wrap;
}

.result_media_item {
    width: 120px;
    height: 120px;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover !important;
    background-position: center !important;
}

.message_sender {
    display: flex;
    flex: 1;
    align-items: flex-end;
    min-width: 0;
}

.typing_box {
    box-sizing: border-box;
    flex: 1 1 auto;
    width: inherit;
    min-width: 0;
    min-height: 20px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    outline: none;
    will-change: width;
    margin: 0 12px;
}

.typing_input {
    height: 50px !important;
    resize: none;
}

.chat_form {
    display: flex;
    flex-grow: inherit;
}

.sending_box {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 52px;
}

.sending_button {
    height: 50px;
    padding: 0 15px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 5px;
}

.comment_box {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    padding: 10px;
}

.comment_name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
}

.comment_time {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    color: #a0a0a0;
    margin-left: 10px;
}

.comment_description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #a0a0a0;
}

.result_selected_media_count {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #303030;
}

/* Project Result */

/* Result modal */

.result_modal_header {
    padding: 15px 30px;
}

.result_modal_content {
    padding: 15px 30px;
}

.result_modal_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
}

.result_modal_close {
    color: #707070;
    cursor: pointer;
    transition: 0.5s;
}

.unselected_star {
    cursor: pointer;
}

.selected_star {
    cursor: pointer;
}

.unselected_star svg {
    color: #a0a0a0;
}

.selected_star svg {
    color: #f79f24;
}

.review_activity_image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
}

.review_activity_title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
}

.check_result_file {
    width: 20px;
    margin: 10px 0 0 10px;
    height: 20px;
}

.request_admin_modal_header {
    padding: 15px 30px;
}

.request_admin_modal_content {
    padding: 15px 30px;
}

.request_admin_modal_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
}

.request_admin_close {
    color: #707070;
    cursor: pointer;
    transition: 0.5s;
}
.request_admin_close:hover {
    color: #c62828;
    cursor: pointer;
    transition: 0.5s;
}

.selected_file_badge {
    background-color: #692f90 !important;
    color: #fff;
    padding: 6px 10px;
    margin-bottom: 10px;
    font-size: 10px;
    position: absolute;
    bottom: -2px;
    left: 8px;
    font-weight: 500;
}

.file_upload_label {
    color: #692f90;
    width: 100px;
    height: 100px;
    display: grid;
    align-items: center;
}

/* balance */
.balance_title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #303030;
}

.withdraw_button_container {
    flex-grow: 1;
    align-self: center;
}

.active_balance_label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.active_balance {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #303030;
}

.balance_history_nominal_container {
    flex-grow: 1;
    align-self: center;
}

.balance_history_list {
    border-bottom: 1px solid #f0f0f0;
    padding-top: 10px;
    padding-bottom: 20px;
}

.balance_history_title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #303030;
}

.balance_history_date {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #a0a0a0;
}

.balance_history_nominal_positive {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: right;
    color: #09a218;
}

.balance_history_nominal_negative {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: right;
    color: #dd2025;
}

.balance_history_nominal_other {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: right;
    color: #a0a0a0;
}

.withdraw_destination_title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.withdraw_summary_total {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.withdraw_summary_bankName {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.withdraw_summary_amount {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #692f90;
}

.withdraw_summary_bankAccount {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #303030;
}

.reschedule_backdrop_modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .9 !important;
}

.reschedule_activity_list {
    padding: 8px 20px;
    font-size: 13px;
}

.reschedule_change_button {
    background: transparent -webkit-gradient(linear, left top, left bottom, from(#944c99), to(#6a308b)) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    font-size: 12px;
    padding: 7px 28px;
    /* font-weight: 500; */
    border-color: #944c99;
    color: #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.reschedule_change_button:hover {
    /*background: transparent linear-gradient(180deg, #f79e42 0%, #f37242 100%) 0% 0% no-repeat*/
    /*   padding-box;*/
    border-color: #944c99;
    color: #fff;
}

.reschedule_change_button:focus {
    background: transparent linear-gradient(180deg, #944c99 0%, #6a308b 100%) 0% 0% no-repeat
    padding-box;
    box-shadow: 0 0 0 0.2rem rgb(112 112 112 / 0.5);
    border-color: #944c99;
}

.reschedule_change_button:not(:disabled):not(.disabled):active {
    background: transparent linear-gradient(180deg, #f79e42 0%, #f37242 100%) 0% 0% no-repeat
    padding-box;
    box-shadow: 0 0 0 0.2rem rgb(112 112 112 / 0.5);
    border-color: #e0e0e0;
}

.reschedule_change_button.disabled,
.reschedule_change_button:disabled {
    color: #a0a0a0;
    background: #e0e0e0 !important;
    border-color: #ddd2eb;
    cursor: not-allowed;
}

.reschedule_activity_status_badge {
    background: #692f9020 0% 0% no-repeat padding-box;
    font-size: 12px !important;
    font-weight: normal;
    color: #692f90 !important;
    padding: 5px 10px;
}

.slider_next_icon {
    /*background-image: url("../public/assets/icons/slide_next_icon.svg");*/
    width: 36px;
    height: 37px;
}

.slider_prev_icon {
    /*background-image: url("../public/assets/icons/slide_prev_icon.svg");*/
    width: 36px;
    height: 37px;
}

.project_info_button {
    cursor: pointer;
}

.project_info_content {
    padding: 0 25px;
    text-align: justify;
}

.project_info_heading {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
}

.info_number_list {
    counter-reset: my-awesome-counter;
    list-style: none;
}
.info_number_item{
    margin: 0 0 0.5rem 0;
    counter-increment: my-awesome-counter;
    position: relative;
}
.info_number_item::before {
    content: counter(my-awesome-counter);
    color: #ffffff;
    font-size: 1rem;
    position: absolute;
    --size: 24px;
    left: calc(-1 * var(--size) - 15px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    background: var(--purple);
    border-radius: 50%;
    text-align: center;
}

.success_add_modal {
    z-index: 2000;
}

.success_add_modal_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.project_success_heading {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.project_success_content {
    padding: 0 40px;
    text-align: center;
    font-weight: 500;
}

@media only screen and (max-width: 767px) {
    .project_activity_thumbnail_image {
        max-width: unset;
        width: 100%;
        border-radius: 10px;
    }
    .project_activity_thumbnail_image img {
        width: 100%;
    }
    .project_vendors_title {
        width: 80%;
    }
    .project_activity_small_label {
        display: block;
    }
    .project_activity_profile_image_frame {
        width: 24px;
        height: 24px;
    }
    .project_activity_profile_image {
        margin-left: unset;
        width: 24px;
        height: 24px;
        object-fit: cover;
    }
    .project_activity_subtitle {
        font-size: 14px;
    }

}
