/* Style the Image Used to Trigger the Modal */
img {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

img:hover {opacity: 0.7;}

/* The Modal (background) */
.image-viewer {
    display: block;
    position: fixed;
    z-index: 1002;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.9);
}
.image-viewer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 84vh;
}
.viewer-left-button {
    display: flex;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
}
.viewer-right-button {
    display: flex;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
}
.image-item {
    visibility: collapse;
}
.viewer-modal-content {
    margin: auto;
    display: block;
    height: 100%;
    width: 700px;
    max-height: 500px;
    object-fit: contain;
}
.viewer-modal-content {
    animation-name: zoom;
    animation-duration: 0.6s;
}
@keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
}
.image-viewer .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}
.image-viewer .close:hover,
.image-viewer .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 700px){
    .viewer-modal-content {
        width: 100%;
        height: 500px;
        object-fit: contain;
    }
}