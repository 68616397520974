/* Navbar */
* {
  font-family: "Poppins", sans-serif;
}

/* Custom Scrollbar */
#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: #944c99;
}

.modal-close {
  cursor: pointer;
}

.navbar-disabled:hover {
  background: none;
  opacity: 1;
}

.topbar-menu {
  border: none;
  background-color: unset;
}

.admin-image-frame {
  height: 34px;
  width: 34px;
  background: linear-gradient(
    210deg,
    #eb9749 0%,
    #e46a44 35%,
    #5b2e75 65%,
    #864886 99.99%
  );
  border-radius: 40% 0;
}

.admin-image {
  border-radius: 40% 0;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4px;
  width: 30px;
  height: 30px;
}

.admin-name {
  line-height: 0.9rem;
  color: #000;
  font-weight: 500;
}

.admin-username {
  color: #a0a0a0;
}

.admin-custom-dropdown {
  background-color: unset !important;
  border: unset !important;
  color: #000 !important;
}

.notification-badge {
  position: relative;
  bottom: 8px;
  right: 8px;
  background: linear-gradient(180deg, #864886 0%, #5b2e75 100%);
  color: #fff;
  font-weight: 500;
  border-radius: 10rem !important;
}

.card-label {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #160e25;
}

.heading-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #712b94;
}

.content-body {
  padding: 20px 30px 20px 140px !important;
}

.content-box {
  background-clip: border-box;
  padding: 20px;
  margin-bottom: 1rem;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.expertise-tab-title {
  font-size: 18px;
  line-height: 27px;
  color: #160e25;
}

/* nav item */

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #864886 !important;
  border-radius: 5px !important;
}

.nav-item a {
  color: #160e25;
}

.nav-item a:hover {
  color: #864886;
}

/* input */

.input-field-group {
  width: auto;
  display: inline;
}

.input-field-group-icon {
  position: relative;
  left: 28px;
  color: #eb9749;
}

.input-field-absolut-icon {
  position: absolute;
  top: 16px;
  left: 28px;
  color: #eb9749;
}

.input-field-group-text {
  padding: 8px 5px 8px 40px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  line-height: 2;
}

.input-field-group-text:focus {
  border: 1px solid #eb9749 !important;
  outline: 0;
}

.button-filter-tag {
  background: none;
  color: #a0a0a0 !important;
  border: none;
  padding: 4px 5px 16px 5px !important;
}

.button-filter-tag.active {
  color: #000 !important;
  border-bottom: 3px solid #2ecc71;
}

.tab-button {
  background: none;
  color: #a0a0a0 !important;
  border: none;
  padding: 4px 5px 10px 5px !important;
  border-radius: 0 !important;
}

.tab-button.active {
  border-bottom: 2px solid rgb(105, 47, 143);
  color: rgb(105, 47, 143) !important;
}

.input-search-box {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  padding: 8px;
}

.input-search {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 2;
  width: 100%;
  padding-left: 10px;
}

.input-search:focus {
  background: #ffffff;
  border: none;
}

.tag-badge {
  margin-left: 5px;
  background: #f0f0f0;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #a0a0a0;
}

.button-filter-tag.active > .tag-badge {
  color: #2ecc71;
  background: rgba(46, 204, 113, 0.1);
}

.custom-badge-success {
  color: #2ecc71;
  background: rgba(46, 204, 113, 0.1);
  padding: 8px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.custom-badge-danger {
  color: #e46a44;
  background: rgba(228, 106, 68, 0.1);
  padding: 8px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

/* default button style */

.button-primary {
  background: transparent linear-gradient(180deg, #944c99 0%, #6a308b 100%) 0%
    0% no-repeat padding-box;
  border-radius: 8px;
  font-size: 14px;
  padding: 7px 40px;
  font-weight: 500;
  border-color: #944c99 !important;
  color: #fff !important;
  transition: 0.5s;
}

.button-primary:hover {
  background: transparent linear-gradient(180deg, #f79e42 0%, #f37242 100%) 0%
    0% no-repeat padding-box;
  border-color: #f79e42;
}

.button-primary:focus {
  background: transparent linear-gradient(180deg, #944c99 0%, #6a308b 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0 0 0 0.2rem rgb(112 112 112 / 0.5);
  border-color: #944c99 !important;
}

.button-primary:not(:disabled):not(.disabled):active {
  background: transparent linear-gradient(180deg, #944c99 0%, #6a308b 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0 0 0 0.2rem rgb(112 112 112 / 0.5);
  border-color: #944c99;
}

.button-primary.disabled,
.button-primary:disabled {
  color: #fff;
  background-color: #ddd2eb;
  border-color: #ddd2eb;
  cursor: not-allowed;
}

.button-outline-primary {
  border-radius: 8px;
  font-size: 14px;
  padding: 7px 40px;
  font-weight: 500;
  border-color: #944c99 !important;
  color: #944c99 !important;
  transition: 0.5s;
}

.button-outline-primary:hover {
  background: transparent linear-gradient(180deg, #944c99 0%, #6a308b 100%) 0%
    0% no-repeat padding-box;
  border-color: #944c99;
  color: #fff !important;
}

.button-outline-primary:focus {
  background: transparent linear-gradient(180deg, #944c99 0%, #6a308b 100%) 0%
    0% no-repeat padding-box;
  box-shadow: unset !important;
  border-color: #944c99;
  color: #fff !important;
}

.button-outline-primary:not(:disabled):not(.disabled):active {
  background: transparent linear-gradient(180deg, #944c99 0%, #6a308b 100%) 0%
    0% no-repeat padding-box;
  box-shadow: unset !important;
  border-color: #944c99;
}

.button-outline-primary.disabled,
.button-outline-primary:disabled {
  color: #fff;
  background-color: #ddd2eb;
  border-color: #ddd2eb;
  cursor: not-allowed;
}

.button-danger {
  background: #e46a44 !important;
  border-radius: 8px;
  font-size: 14px;
  padding: 7px 40px;
  font-weight: 500;
  border-color: #e46a44;
  color: #fff !important;
}

.button-danger:hover {
  background: transparent linear-gradient(180deg, #f79e42 0%, #f37242 100%) 0%
    0% no-repeat padding-box;
  border-color: #f79e42;
}

.button-danger:focus {
  background: #e46a44;
  box-shadow: 0 0 0 0.2rem rgb(112 112 112 / 0.5);
  border-color: #e46a44;
}

.button-danger:not(:disabled):not(.disabled):active {
  background: #e46a44;
  box-shadow: 0 0 0 0.2rem rgb(112 112 112 / 0.5);
  border-color: #e46a44;
}

.button-danger.disabled,
.button-danger:disabled {
  color: #fff;
  background-color: #ddd2eb;
  border-color: #ddd2eb;
  cursor: not-allowed;
}

.form-control {
  border: 1px solid #e0e0e0 !important;
}

.form-control::placeholder {
  font-weight: 400;
  font-size: 14px;
}

.form-control:hover {
  box-shadow: unset !important;
  border: 1px solid #eb9749 !important;
}

.form-control:focus {
  box-shadow: unset !important;
  border: 1px solid #eb9749 !important;
}

.form-label {
  color: #000;
  font-weight: normal;
  font-size: 14px;
}

.profile_tab_style {
  border-bottom: none !important;
}
.profile_tab_style .nav-link.active {
  border-color: #fff #fff #2ecc71 !important;
  color: #160e25;
  font-weight: normal;
  padding-bottom: 15px !important;
}

.profile_tab_style .nav-link:hover {
  transition: 0.5s;
  border-bottom: 3px solid #707070;
  border-color: #fff #fff #2ecc71 !important;
  color: #160e25;
}

.profile_tab_style .nav-item {
  font-weight: normal;
  color: #a0a0a0;
  font-size: 14px;
}

.data_diri_tab_menu .nav-link.active {
  padding: 10px !important;
}

.data_diri_tab_menu .nav-link {
  padding: 20px 10px;
}

.other-city-tag {
  background: rgba(134, 72, 134, 0.2);
  font-size: 14px;
  color: #864886;
  padding: 5px 10px;
}

.other-city-tag-label {
  max-width: 130px;
  width: 130px;
  justify-content: center;
  align-items: center;
}

.other-city-tag-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grey-tag {
  background: #e6e7e8;
  font-size: 12px;
  color: #939598;
  padding: 5px 10px !important;
}

.grey-tag-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grey-tag-label {
  justify-content: center;
  align-items: center;
  font-weight: normal;
}

.grey-tag-close {
  font-size: 14px;
  cursor: pointer;
}

.revision-title-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-description {
  font-size: 14px;
  color: #707070;
  font-weight: normal;
}

/* upload frame */
.file-upload-frame {
  border: 1px dashed #e0e0e0;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  min-height: 150px;
  transition: 0.5s;
}

.file-upload-frame:hover {
  border: 1px dashed #f37334;
}
.file-upload-label {
  color: #692f90;
  width: 100%;
  height: 150px;
  display: grid;
  align-items: center;
}

.file-upload-image {
  width: 80%;
  height: 100px;
  object-fit: cover;
}

/* badge */
.profile-badge {
  background: rgba(134, 72, 134, 0.2);
  font-size: 14px !important;
  line-height: 21px;
  text-align: center;
  color: #864886;
  font-weight: normal !important;
  padding: 10px 20px !important;
}

.aboutme-content p {
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #a0a0a0;
}

/* Blog Section*/
.horizontal-card {
  display: flex;
}

.horizontal-card-image img {
  object-fit: cover;
  -webkit-object-fit: cover;
  width: 350px;
  height: 210px;
  border-radius: 10px;
}

.horizontal-card-body h4 {
  font-size: 20px;
  letter-spacing: 0px;
  color: #303030;
}

.horizontal-card-body span {
  font-size: 16px;
  letter-spacing: 0px;
  color: #707070;
}

.blog-page {
  background-color: #fff;
}

.blog-title a {
  color: #000;
  text-decoration: none;
}

.blog-title a:hover {
  color: #000;
  text-decoration: none;
}

.horizontal-card-body p {
  font-size: 16px;
  letter-spacing: 0px;
  color: #707070;
}

.other-blog-title {
  letter-spacing: 0px;
  color: #303030;
  font-size: 22px;
}

.other-blog-card .card-title {
  color: #303030;
  opacity: 1;
  font-size: 18px;
}

.blog-date {
  letter-spacing: 0px;
  color: #707070;
  font-size: 16px;
}

/* Styles for nav on Operational */
.data-filter-operational {
  padding: 1rem 0;
}
.data-filter-operational .form-check {
  padding: 0.25rem 1.25rem;
}

.data-filter-operational p,
.form-check-label {
  color: #707070;
}

.content-item {
  padding: 10px;
  border: solid 1px #ebebeb;
  border-radius: 4px;
  max-height: 100%;
  height: 100%;
}

.content-item .content-item-title {
  font-weight: 500;
  letter-spacing: 0px;
  color: #707070;
  padding: 5px;
  margin-bottom: 0;
}

.content-item .content-item-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 10px;
}

.item-days {
  border-bottom: 1px solid #ebebeb;
  padding: 5px;
}

.item-days .item-days-title {
  display: flex;
  justify-content: space-between;
}

.item-days-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 0 0 10px 0;
}

.item-days-time input {
  width: 100px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 6px;
  letter-spacing: 0px;
  color: #707070;
}

.item-days-title p {
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 5px;
}

.item-days-title .custom-control {
  z-index: 0;
}

.item-days-title .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #75628d;
  background-color: #6a308b;
}

.btn-content,
.btn-content:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #75628d !important;
  padding: 0;
  line-height: 1;
  height: 20px;
}

.content-item .svg {
  color: #75628d;
}

.item-schedule {
  display: grid;
  grid-template-columns: 65px 1fr;
  align-items: center;
  border-bottom: solid 1px #ebebeb;
  padding: 6px 0;
}

.item-schedule-profil img {
  width: 40px;
  border-radius: 50%;
}

.item-schedule-detail h5 {
  font-size: 16px;
  letter-spacing: 0px;
  color: #707070;
}

.item-schedule-detail p {
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 0px;
  color: #707070;
}

.item-offdays {
  padding: 6px 0;
  border-bottom: solid 1px #ebebeb;
}

.item-offdays h5 {
  font-size: 16px;
  letter-spacing: 0px;
  color: #f37334;
}

.item-offdays p {
  font-size: 14px;
  margin: 0;
}

.react-datepicker-wrapper,
.datepicker-modal {
  width: 100%;
}

.datepicker-modal {
  padding: 6px;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
}

.datepicker-modal:focus {
  color: #707070;
  background-color: #fff;
  border-color: #75628d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(164 145 188 / 25%);
}

.workday-divider {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* End Styles for nav on Operational */

/* upload frame */
.file-upload-frame {
  border: 1px dashed #e0e0e0;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  min-height: 150px;
  transition: 0.5s;
}

.file-upload-frame:hover {
  border: 1px dashed #f37334;
}
.file-upload-label {
  color: #692f90;
  width: 100%;
  height: 150px;
  display: grid;
  align-items: center;
}

.file-upload-image {
  width: 80%;
  height: 100px;
  object-fit: cover;
}
