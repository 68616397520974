.centered_element {
  min-height: 100%;
  min-height: calc(100vh - 8rem);
  display: flex;
  align-items: center;
}

.box_element {
  box-shadow: 0px 0px 8px #adb5bd;
  padding: 30px;
  border-radius: 5px;
}
